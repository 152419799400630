import React, { useState } from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';

import eulogo from './eulogo.png';

/*
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: 'url(https://source.unsplash.com/random/?electricity)',
        backgroundSize: 'cover',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));
*/

const GridRoot = styled(Grid)(({ theme }) => ({
    height: '100vh'
}));

const GridImage = styled(Grid)(({ theme }) => ({
    backgroundImage: 'url(https://source.unsplash.com/random/?electricity)',
    backgroundSize: 'cover',
}));

const DivPaper = styled(Paper)(({ theme }) => ({
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
}));

const DivForm = styled(Grid)(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
}));

const SumbitButton = styled(Grid)(({ theme }) => ({
    margin: theme.spacing(3, 0, 2),
    color: theme.palette.primary
}));


async function loginUser(credentials)
{
    console.log('loginUser', process.env.REACT_APP_ADMIN_URL);

    return fetch(process.env.REACT_APP_ADMIN_URL + '/api/auth/signin', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function Signin()
{
    const [email, setUserName] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e =>
    {
        e.preventDefault();
        const response = await loginUser({
            email,
            password
        });

        if (response.roles.includes('admin'))
        {
            swal("Success", "", "success", {
                buttons: false,
                timer: 2000,
            })
                .then((value) =>
                {
                    sessionStorage.setItem('accessToken', true);
                    sessionStorage.setItem('user', JSON.stringify(response));
                    window.location.href = "/";
                });
        } else
        {
            swal("Failed", "", "error");
        }
    };

    return (
        <GridRoot container >
            <CssBaseline />
            <GridImage item xs={false} md={7} > </GridImage>
            <Grid item xs={12} md={5} component={Paper} elevation={6} square>
                <DivPaper>

                    <img src={eulogo} alt="Logo" />

                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <form noValidate onSubmit={handleSubmit}>
                        <DivForm>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Email Address"
                                onChange={e => setUserName(e.target.value)}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="password"
                                name="password"
                                label="Password"
                                type="password"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"

                            ><SumbitButton> Sign In</SumbitButton>

                            </Button>
                        </DivForm>
                    </form>
                </DivPaper>
            </Grid>
        </GridRoot>
    );
}