import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';

import Button from '@mui/material/Button';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HouseIcon from '@mui/icons-material/House';
import BusinessIcon from '@mui/icons-material/Business';
import TransformIcon from '@mui/icons-material/Transform';
import RuleIcon from '@mui/icons-material/Rule';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DangerousIcon from '@mui/icons-material/Dangerous';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import PendingIcon from '@mui/icons-material/Pending';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import StarIcon from '@mui/icons-material/Star';


import { format } from 'date-fns';
import { stringify } from 'uuid';

export default function ScraperLogs(props) {
  let didInit = false;
  const [logsList, setLogsList] = React.useState(null);

  React.useEffect(() => {
    if (!didInit) {
      didInit = true;
      fetch('/api/scraperlogs/all')
        .then((res) => res.json())
        .then((data) => {
          setLogsList([...data]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  async function validateLogs(log_id, region) {
    let response;

    props.getScraperStatus().then((status) => {
      console.log(status);
      props.handleScrapeStatusChange(status);
    });

    props.handleSetDelay(1000);


    if (region === 'Vic') {
      response = await fetch('/api/VEC/actualresult/' + log_id);
    } else {
      response = await fetch('/api/EME/actualresult/' + log_id);
    }

    const actualResult = await response.json();

    const updateResult = await fetch('/api/scraperlogs/updateActualResult', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ log_id: log_id, result: actualResult }),
    });

    fetch('/api/scraperlogs/validate/' + log_id, {
      method: 'get',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).then(
      (response) => {

         fetch('/api/scraperlogs/all')
          .then((res) => res.json())
          .then((data) => {
            setLogsList([...data]);
          })
          .catch((err) => {
            console.log(err.message);
          });
      },
      (error) => {
        console.log(error);
      }
    ); // Displaying the stringified data in an alert popup
  }


  async function transformPlans(scraper_log_id,distributor){

    const sendData = {scraper_log_id:scraper_log_id, distributor:distributor };
     props.getScraperStatus().then((status) => {
       console.log(status);
       props.handleScrapeStatusChange(status);
     });

     props.handleSetDelay(props.API_REFRESH_INTERVAL);

     
    fetch('/api/plantransform', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(sendData),
    }).then(
      (response) => {

       

        fetch('/api/scraperlogs/all')
          .then((res) => res.json())
          .then((data) => {
            setLogsList([...data]);
          })
          .catch((err) => {
            console.log(err.message);
          });
      },
      (error) => {
        console.log(error);
      }
    );


  }

  return (
    logsList && (
      <TableContainer>
        <Table size='small'>
          <TableHead>
            <TableRow>
              <TableCell> Run DateTime </TableCell>
              <TableCell> Retailer </TableCell>
              <TableCell> Distributor </TableCell>
              <TableCell> EnergyType </TableCell>
              <TableCell> Plan Type </TableCell>
              <TableCell> Status </TableCell>
              <TableCell> Method </TableCell>
              <TableCell> Expected Result </TableCell>
              <TableCell> Actual Result </TableCell>
              <TableCell> Validation </TableCell>
              <TableCell></TableCell>
              <TableCell> Transformed (EU Model) </TableCell>
              <TableCell> Transformed Date </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {logsList.map((f) => {

              let distributor = props.distributors.find((obj) => {
                return obj.key === f.distributor_key;
              });

              if(!distributor) distributor = {};

              distributor.controlledload = f.controlledload;

              return (
                <TableRow key={f.createdDate}>
                  <TableCell>{f.createdDate}</TableCell>
                  <TableCell>{f.cdrBrand && f.cdrBrand.brandName}</TableCell>
                  <TableCell>
                    {distributor && distributor.distributor}
                  </TableCell>
                  <TableCell>
                    {distributor && distributor.energytype === 'GAS' && (
                      <WhatshotIcon sx={{ color: 'red' }} />
                    )}
                    {distributor.energytype === 'ELEC' && (
                      <ElectricBoltIcon sx={{ color: '#fdee00' }} />
                    )}{' '}
                    <br /> {distributor && distributor.energytype}{' '}
                    {f.controlledload && '+CL'}
                  </TableCell>
                  <TableCell>
                    {distributor && distributor.plantype === 'home' && (
                      <HouseIcon sx={{ color: 'green' }} />
                    )}
                    {distributor && distributor.plantype === 'business' && (
                      <BusinessIcon sx={{ color: 'darkblue' }} />
                    )}{' '}
                    <br /> {distributor && distributor.plantype}
                  </TableCell>
                  <TableCell>
                    {f.status === 'completed' && (
                      <CheckCircleIcon sx={{ color: 'green' }} />
                    )}
                    {f.status === 'initiated' && (
                      <RotateRightIcon sx={{ color: 'orange' }} />
                    )}
                    {f.status}
                  </TableCell>
                  <TableCell>
                    {f.method === 'file' && (
                      <UploadFileIcon sx={{ color: 'red' }} />
                    )}
                    {f.method === 'scheduled' && (
                      <AccessTimeIcon sx={{ color: 'green' }} />
                    )}
                    {f.method === 'manual' && (
                      <AdsClickIcon sx={{ color: 'orange' }} />
                    )}
                    {f.method}
                  </TableCell>
                  <TableCell>{f.expectedResult}</TableCell>
                  <TableCell>{f.actualResult}</TableCell>
                  <TableCell>
                    {f.validation === 'validated' && (
                      <CheckCircleIcon sx={{ color: 'green' }} />
                    )}{' '}
                    {f.validation === 'invalid' && (
                      <DangerousIcon sx={{ color: 'red' }} />
                    )}
                    {f.validation === 'unvalidated' && (
                      <PendingIcon sx={{ color: 'orange' }} />
                    )}
                    {f.validation}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => validateLogs(f._id, distributor.region)}
                      startIcon={<RuleIcon />}
                      disabled={
                        props.scrapeStatus !== 'READY' ||
                        distributor.distributor === undefined
                      }
                    >
                      Validate
                    </Button>
                  </TableCell>
                  <TableCell>
                    {f.transformed && (
                      <CheckCircleIcon sx={{ color: 'green' }} />
                    )}{' '}
                    {!f.transformed && <DangerousIcon sx={{ color: 'red' }} />}
                  </TableCell>
                  <TableCell>
                    {logsList ? (
                      <LatestBadge logItem={f} logList={logsList}></LatestBadge>
                    ) : (
                      ''
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      size='small'
                      onClick={() => transformPlans(f._id, distributor)}
                      startIcon={<TransformIcon />}
                      disabled={
                        props.scrapeStatus !== 'READY' ||
                        distributor.distributor === undefined
                      }
                    >
                      Transform
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}



function LatestBadge({logItem,logList}) {
  
  const transformed_date = new Date(logItem.transformed_date);

  const formattedDate =
    transformed_date && logItem.transformed_date
      ? format(transformed_date, 'dd MMM yy HH:mm')
      : '';

  function checkLatest() {


    if (!logItem.transformed_date) return false;
    if (!logList) return false;
    if(logList.length === 0) return false;

    const distributorList = logList.filter(d => {
      return d.distributor_key === logItem.distributor_key;
    });

    
    if(distributorList.length <= 1)  return true;

    const sortList = distributorList.sort((a, b) => { return  new Date(b.transformed_date) - new Date(a.transformed_date) });

    if(sortList[0].transformed_date === logItem.transformed_date){
      return true;
    }

    return false;
  };


  return (
    <Box>
      { formattedDate } { checkLatest() ? <StarIcon sx={{ color: 'green' }} /> : '' }
    </Box>
  );
}