import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PendingIcon from '@mui/icons-material/Pending';

import { styled } from '@mui/material/styles';

import { format, differenceInDays } from 'date-fns';

export default function CdrScraperStatsBadge(props) {
    

  const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.info.contrastText,
  }));

  const ItemBadge = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  }));

  const ScrapeList = styled(Grid)(({ theme }) => ({
    textAlign: 'left',
    fontSize: '0.8rem',
    alignItems: 'center',
    direction: 'row',
    gap: 1,
  }));

  const date = props.stats && props.stats.length > 0 ? new Date(props.stats[0].scrapeDate) : '';

  const formattedDate = date ? format(date, 'dd MMM yy') : '';

  const days = differenceInDays(new Date(), new Date(date)) >= 7;

  const title = props.controlledload ? 'Controlled Load' : '';

  return (
    props.stats &&
    props.stats.length > 0 && (
      <Box
        sx={{
          width: 400,
          borderRadius: '15px',
          m: 0.5,
          textTransform: 'capitalize',
          color: 'info.contrastText',
          padding: 1,
          background: days
            ? 'linear-gradient(to right bottom, #f7af45,#cb4c00)'
            : 'linear-gradient(to right bottom, #1e88e5,#0d47a1)',
        }}
      >
        <b style={{ color: 'lightblue' }}>{title}</b>
        <Grid container spacing={1}>
          <Grid item xs={2}>
            {' '}
            <Item>
              {' '}
              <ItemBadge>{props.stats[0].expectedResult}</ItemBadge>Plans
            </Item>
          </Grid>
          <Grid item xs={4}>
            {' '}
            <Item>
              {' '}
              <ItemBadge>{formattedDate}</ItemBadge>Last Scrape{' '}
            </Item>
          </Grid>
          <Grid item xs={6}>
            {props.stats.map((s) => {
              const date = new Date(s.createdDate);

              const formattedDate = format(date, 'dd/MM/yy p');
              return (
                <ScrapeList key={s._id}>
                  {formattedDate} | {s.expectedResult}{' '}
                  {s.transformed && (
                    <CheckCircleRoundedIcon
                      sx={{ color: 'lime' }}
                      fontSize='small'
                    />
                  )}{' '}
                 
                  {!s.transformed && (
                    <PendingIcon sx={{ color: 'orange' }} fontSize='small' />
                  )}
                </ScrapeList>
              );
            })}
          </Grid>
        </Grid>
      </Box>
    )
  );
}
