import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';


import CdrScraperStatsBadge from './CdrScraperStatsBadge';

export default function CdrScraper(props) {

    let didInit = false;
    const [brands, setBrands] = React.useState([]);
    const [updateAll,setUpdateAll] = React.useState(false);
    
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setUpdateAll(event.target.checked);
    };




    React.useEffect(() => {
      if (!didInit) {
        didInit = true;
        fetch('/api/cdr/allBrandStats', { cache: 'no-store' })
          .then((res) => res.json())
          .then((data) => {
            setBrands([...data]);
          })
          .catch((err) => {
            console.log(err.message);
          });
      }
    }, [props.scrapeStatus]);

    function runScraper(brand) {

        console.log('props.API_REFRESH_INTERVAL ' + props.API_REFRESH_INTERVAL);

      fetch('/api/cdr/latestSummaryBrand', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ brand: brand, updateAll: updateAll}),
      }).then(
        (response) => {
          
        },
        (error) => {
          console.log(error);
        }
      ); // Displaying the stringified data in an alert popup

      props.getScraperStatus().then((status) => {
        console.log('getScraperStatus: ' + status);

        props.handleScrapeStatusChange(status);
      });
      props.handleSetDelay(props.API_REFRESH_INTERVAL);
    }


    function runScraperAll() {
      console.log('props.API_REFRESH_INTERVAL ' + props.API_REFRESH_INTERVAL);

      fetch('/api/cdr/process', {
        method: 'post',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ method: 'manual', updateAll: updateAll }),
      }).then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      ); // Displaying the stringified data in an alert popup

      props.getScraperStatus().then((status) => {
        console.log('getScraperStatus: ' + status);

        props.handleScrapeStatusChange(status);
      });
      props.handleSetDelay(props.API_REFRESH_INTERVAL);
    }


    return (
      <Box>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <h3>CDR Brands</h3>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant='outlined'
              disabled={props.scrapeStatus !== 'READY'}
              onClick={() => runScraperAll()}
            >
              Run All{' '}
              {props.scrapeStatus !== 'READY' ? <CircularProgress /> : ''}
            </Button>
          </Grid>
          <Grid item xs={2}>
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={updateAll} onChange={handleChange} />}
                label='Update All Plans'
              />
            </FormGroup>
          </Grid>
        </Grid>

        {brands && (
          <TableContainer>
            <Table size='small'>
              <TableBody>
                {brands.map((b) => (
                  <TableRow key={b.brand.brandName}>
                    <TableCell>{b.brand.brandName}</TableCell>
                    <TableCell>{b.brand.publicBaseUri_override}</TableCell>
                    <TableCell>
                      <Button
                        variant='outlined'
                        disabled={
                          props.scrapeStatus !== 'READY' ||
                          b.brand.publicBaseUri_override === undefined
                        }
                        onClick={() => runScraper(b.brand)}
                      >
                        Run{' '}
                        {props.scrapeStatus !== 'READY' ? (
                          <CircularProgress />
                        ) : (
                          ''
                        )}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <CdrScraperStatsBadge stats={b.stats}>
                        {' '}
                      </CdrScraperStatsBadge>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    );
}
