import React, { useState } from 'react';

import Box from '@mui/material/Box';

import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function UploadScrape(props){

    let fileReader;
    const [distributor, setDistributor] = useState('');
    const [distributorName, setDistributorName] = useState('');
    const [energytype, setEnergytype] = useState('');
    const [propertytype, setPropertytype] = useState('');
    const [controlledLoad, setControlledLoad] = useState('');
    const [offersList, setOffersList] = useState('');
    const [uploadStatus, setUploadStatus] = useState({ uploadState: false, success: null});
    const [dropdownDistributors, setDropDownDistributors] = useState([]);
    
    const [data, setData] = useState('');



    React.useEffect(() => {

        setEnergytype(data.selectedEnergyType);
        

    }, [data]);

    React.useEffect(() => {
      validateData();
      filterDropDownDistributors();
    }, [
      data,
      energytype,
      propertytype,
    ]);

    const filterDropDownDistributors = () => {

      const energy = energytype === 'Electricity' ? 'ELEC' : 'GAS';

      const d = props.distributors.filter((d) => {
        return (
          d.plantype.toLowerCase() === propertytype.toLowerCase() &&
          d.energytype.toLowerCase() === energy.toLowerCase() &&
          (d.distributor
            .toLowerCase()
            .includes(distributorName.toLowerCase()) ||
            distributorName.toLowerCase().includes(d.distributor.toLowerCase()))
        );
      }); 

      setDropDownDistributors(d);

    }
    

    const handleChange = (event) => {
      setPropertytype(event.target.value);
    }; 

    const chooseDistributor = (event) => {
      setDistributor(event.target.value);
    }; 

    
    const validateData = () => {

        if(data && energytype) {
            if(data.offers[energytype].offersList.length > 0) {

                const dn =
                data.offers[energytype].offersList[0].offerDetails[0].distributor;

                const d = props.distributors.filter( (d) => { return d.distributor.toLowerCase() === dn.toLowerCase() && d.plantype.toLowerCase() === propertytype});
                
                
                setDistributorName(dn);
                setOffersList(data.offers[energytype].offersList);
                
                if (
                  Number(data.offers[energytype].offersList[0].offerDetails[0]
                    .controlledLoadRate) > 0
                ){
                  setControlledLoad(true);
                }else{
                    setControlledLoad(false);
                }

                return true;
            }
        }

        return false;
    };


    const handFileRead = (e) => {
        const content = fileReader.result;

       console.log(content);

       setData(JSON.parse(content));
        // do something with content
        
    };

    const handFileChosen = (file) => {
         setUploadStatus({ uploadState: false, success: null });
        fileReader = new FileReader();
        fileReader.onloadend = handFileRead;
        fileReader.readAsText(file);

       
    };


    function uploadOffers(data,distributor,controlledLoad) {
        const bodyData = {
          data: data,
          distributor: distributor,
          controlledload: controlledLoad,
        };

        fetch('/api/uploadScrapeData', {
          method: 'post',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        })
          .then((res) => { console.log('uploadOffers success' + JSON.stringify(res)); setUploadStatus({ uploadState: true, success:true }) })
          .catch((err) => {
            console.log(err.message);
            setUploadStatus({ uploadState: true, success:false });
          });
    }


    return (
      <Stack spacing={2}>
        <Item>
          <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='property-type-select-label'>Property Type</InputLabel>
            <Select
              labelId='property-type-select-label'
              id='property-type-select'
              value={propertytype}
              label='Property Type'
              onChange={handleChange}
            >
              <MenuItem value='home'>
                <em>Home</em>
              </MenuItem>
              <MenuItem value='business'>
                <em>Business</em>
              </MenuItem>
            </Select>
          </FormControl>
          <Button variant='contained' component='label'>
            Choose File
            <input
              type='file'
              id='file'
              accept='.json'
              onChange={(e) => handFileChosen(e.target.files[0])}
              hidden
            />
          </Button>
        </Item>
        {data ? <Item>File has been selected </Item> : null}
        <Item>
          <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
            <InputLabel id='distributor-type-select-label'>
              Distributor
            </InputLabel>
            <Select
              labelId='distributor-type-select-label'
              id='distributor-type-select'
              value={distributor}
              label='Distributor'
              onChange={chooseDistributor}
            >
              {dropdownDistributors ? (
                dropdownDistributors.map((distributor) => {
                  return (
                    <MenuItem value={distributor}>
                      <em>{distributor.distributor}</em>
                    </MenuItem>
                  );
                })
              ) : (
                <MenuItem></MenuItem>
              )}
            </Select>
          </FormControl>
        </Item>
        <Item>
          {energytype && distributorName && distributor && data.offers ? (
            <div>
              <div>Energy Type: {energytype} </div>
              <div>Distributor: {distributorName} </div>
              <div>Distributor Info: {JSON.stringify(distributor)} </div>
              <div>Controlled Load: {String(controlledLoad)} </div>
              <div>
                Offers:{' '}
                {JSON.stringify(data.offers[energytype].offersList.length)}
              </div>
            </div>
          ) : (
            'Invalid File Format Selected'
          )}
        </Item>
        <Item>
          {data &&
          energytype &&
          distributorName &&
          distributor &&
          data.offers[energytype].offersList.length > 0 ? (
            <Button
              variant='contained'
              component='label'
              disabled={uploadStatus.uploadState}
              onClick={() =>
                uploadOffers(offersList, distributor, controlledLoad)
              }
            >
              Upload Offers
            </Button>
          ) : null}
        </Item>
        {uploadStatus.uploadState ? (
          <Item>
            {uploadStatus.success ? 'Upload Successful' : 'Upload Failed'}
          </Item>
        ) : null}
      </Stack>
    );
}