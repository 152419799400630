import React, { useMemo } from 'react';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { format, differenceInDays } from 'date-fns';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import WhatshotIcon from '@mui/icons-material/Whatshot';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import HouseIcon from '@mui/icons-material/House';
import BusinessIcon from '@mui/icons-material/Business';

import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

import { styled } from '@mui/material/styles';

import eulogo from './eulogo.png';
import emelogo from './eme_logo.gif';
import veclogo from './vec_logo.png';

import Signin from './Signin';

import ScraperLogs from './ScraperLogs';

import ScraperStatsBadge from './ScraperStatsBadge';

import UploadScrape from './UploadScrape';

import CdrScraper from './CdrScraper';


const API_REFRESH_INTERVAL = 5000;

function App() {

  console.log('REACT_APP_ENV: ', process.env.REACT_APP_ENV);
  //console.log('loginUser', process.env.REACT_APP_ADMIN_URL);

  let didInit = false;
  const token = sessionStorage.getItem('accessToken');

  const [distributors, setData] = React.useState(null);

  const [scrapeStats, setStats] = React.useState([]);

  const [scrapeStatus, setStatus] = React.useState(null);

  const [env, setENV] = React.useState(null);

  const [tabIndex, setTabIndex] = React.useState(0);

  const [delay, setDelay] = React.useState(null);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    getScraperStatus().then((status) => {
      handleScrapeStatusChange(status);
    });
    setDelay(API_REFRESH_INTERVAL);
  };

  useInterval(async () => {
    getScraperStatus().then((status) => {
      if (status === 'READY') {
        setDelay(null);
        handleScrapeStatusChange(status);
      } else {
        setDelay(API_REFRESH_INTERVAL);
        console.log('delay: ' + delay);
      }
    });
  }, delay);

  React.useEffect(() => {
    getScraperStatus().then((status) => {
      setStatus(status);

      if (status === 'READY') {
        setDelay(null);
        handleScrapeStatusChange(status);
      } else {
        setDelay(API_REFRESH_INTERVAL);
      }
    });
  }, []);

  function handleScrapeStatusChange(status) {
    setStatus(status);

    fetch('/api/AllStats', { cache: 'no-store' })
      .then((res) => res.json())
      .then((data) => {
        setStats([...data]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }

  function handleSetDelay(delay) {
    console.log('handleSetDelay: ' + delay);
    setDelay(delay);
  }

  React.useEffect(() => {
    fetch('/api/distributors')
      .then((res) => res.json())
      .then((data) => {
        setData(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  React.useEffect(() => {
    if (!didInit) {
      didInit = true;
      fetch('/api/AllStats', { cache: 'no-store' })
        .then((res) => res.json())
        .then((data) => {
          setStats([...data]);
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  }, []);

  React.useEffect(() => {
    fetch('/api/Environment')
      .then((res) => res.json())
      .then((data) => {
        setENV(data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  if (!token) {
    return <Signin />;
  }

  return (
    <div className='App'>
      <BrowserRouter>
        <Container maxWidth='xl' component={Paper} elevation={12}>
          <h3
            style={{
              color:
                process.env.REACT_APP_ENV === 'development' ? 'red' : 'black',
            }}
          >
            {process.env.REACT_APP_ENV}
          </h3>

          <Typography
            color='textPrimary'
            gutterBottom
            variant='h2'
            align='center'
          >
            <img src={eulogo} alt='Logo' /> Energy Umpire Plan Scraper
          </Typography>
          <h2 style={{ color: 'green' }}>
            Scraper Status:{' '}
            <span
              style={{ color: scrapeStatus === 'READY' ? 'green' : 'orange' }}
            >
              {scrapeStatus}
            </span>
            {scrapeStatus !== 'READY' ? <CircularProgress /> : ''}
          </h2>

          <Tabs value={tabIndex} onChange={handleTabChange}>
            <Tab label='CDR' />
            <Tab label='Scraper' />
            <Tab label='Files' />
            <Tab label='Logs' />
            <Tab label='Upload Scrape' />
          </Tabs>

          {tabIndex === 0 && (
            <CdrScraper
              getScraperStatus={getScraperStatus}
              scrapeStatus={scrapeStatus}
              handleScrapeStatusChange={handleScrapeStatusChange}
              handleSetDelay={handleSetDelay}
              API_REFRESH_INTERVAL={API_REFRESH_INTERVAL}
            ></CdrScraper>
          )}
          {tabIndex === 1 && (
            <ScraperTab
              distributors={distributors}
              scrapeStatus={scrapeStatus}
              scrapeStats={scrapeStats}
              handleScrapeStatusChange={handleScrapeStatusChange}
              handleSetDelay={handleSetDelay}
            ></ScraperTab>
          )}
          {tabIndex === 2 && <FileTab></FileTab>}
          {tabIndex === 3 && (
            <ScraperLogs
              distributors={distributors}
              getScraperStatus={getScraperStatus}
              handleScrapeStatusChange={handleScrapeStatusChange}
              handleSetDelay={handleSetDelay}
              API_REFRESH_INTERVAL={API_REFRESH_INTERVAL}
              scrapeStatus={scrapeStatus}
            ></ScraperLogs>
          )}
          {tabIndex === 4 && (
            <UploadScrape distributors={distributors}></UploadScrape>
          )}
        </Container>
      </BrowserRouter>
    </div>
  );
}

function FileTab() {
  const [fileList, setFileList] = React.useState(null);

  React.useEffect(() => {
    fetch('/api/files')
      .then((res) => res.json())
      .then((data) => {
        setFileList([...data]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  function getDownloadFile(file) {
    fetch('api/files/' + file)
      .then((res) =>
        res.blob().then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = file;
          a.click();
        })
      )
      .catch((err) => {
        console.log(err.message);
      });
  }

  return (
    fileList && (
      <TableContainer>
        <Table size='small'>
          <TableBody>
            {fileList.map((f) => (
              <TableRow key={f.filename}>
                <TableCell>
                  <Link
                    key={f.filename}
                    onClick={() => getDownloadFile(f.filename)}
                  >
                    {f.filename}
                  </Link>
                </TableCell>
                <TableCell>{f.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    )
  );
}

function ScraperTab({
  distributors,
  scrapeStats,
  scrapeStatus,
  handleScrapeStatusChange,
  handleSetDelay,
}) {
  const filterAll = (item) => item;
  const filterVicElecHome = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'VIC';
  const filterVicGasHome = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'VIC';
  const filterNSWElecHome = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'NSW';
  const filterNSWGasHome = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'NSW';
  const filterQLDElecHome = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'QLD';
  const filterQLDGasHome = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'QLD';
  const filterSAElecHome = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'SA';
  const filterSAGasHome = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'SA';
  const filterACTElecHome = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'ACT';
  const filterACTGasHome = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'HOME' &&
    item.region.toUpperCase() === 'ACT';

  const filterVicElecBusiness = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'VIC';
  const filterVicGasBusiness = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'VIC';
  const filterNSWElecBusiness = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'NSW';
  const filterNSWGasBusiness = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'NSW';
  const filterQLDElecBusiness = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'QLD';
  const filterQLDGasBusiness = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'QLD';
  const filterSAElecBusiness = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'SA';
  const filterSAGasBusiness = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'SA';
  const filterACTElecBusiness = (item) =>
    item.energytype.toUpperCase() === 'ELEC' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'ACT';
  const filterACTGasBusiness = (item) =>
    item.energytype.toUpperCase() === 'GAS' &&
    item.plantype.toUpperCase() === 'BUSINESS' &&
    item.region.toUpperCase() === 'ACT';

  return (
    <Box>
      {!distributors ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterVicElecHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='VIC ELEC HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterVicGasHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='VIC GAS HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterNSWElecHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='NSW ELEC HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterNSWGasHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='NSW GAS HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors  ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterQLDElecHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='QLD ELEC HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors  ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterQLDGasHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='QLD GAS HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterSAElecHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='SA ELEC HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterSAGasHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='SA GAS HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterACTElecHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='ACT ELEC HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterACTGasHome}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='ACT GAS HOME'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterVicElecBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='VIC ELEC BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterVicGasBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='VIC GAS BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterNSWElecBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='NSW ELEC BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterNSWGasBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='NSW GAS BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterQLDElecBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='QLD ELEC BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterQLDGasBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='QLD GAS BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterSAElecBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='SA ELEC BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterSAGasBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='SA GAS BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterACTElecBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='ACT ELEC BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}

      {!distributors   ? (
        'Loading...'
      ) : (
        <DistributorList
          key={distributors.distributor}
          scrapeStats={scrapeStats}
          distributors={distributors}
          filter={filterACTGasBusiness}
          status={scrapeStatus}
          statusChange={handleScrapeStatusChange}
          header='ACT GAS BUSINESS'
          handleSetDelay={handleSetDelay}
        />
      )}
    </Box>
  );
}

function StatsCell(props) {
  const date = new Date(props.stats.scrape_date);

  const formattedDate = format(date, 'dd MMM yy');

  const days = differenceInDays(new Date(), date) >= 7;

  const Item = styled(Box)(({ theme }) => ({
    padding: theme.spacing(0),
    textAlign: 'center',
    color: theme.palette.info.contrastText,
  }));

  const ItemBadge = styled(Box)(({ theme }) => ({
    textAlign: 'center',
    fontSize: '1.4rem',
    fontWeight: 'bold',
  }));

  const ScrapeList = styled(Box)(({ theme }) => ({
    textAlign: 'left',
    fontSize: '0.8rem',
  }));

  return (
    <Box
      sx={{
        width: 320,
        borderRadius: '15px',
        m: 0.5,
        textTransform: 'capitalize',
        color: 'info.contrastText',
        padding: 1,
        background: days
          ? 'linear-gradient(to right bottom, #f7af45,#cb4c00)'
          : 'linear-gradient(to right bottom, #1e88e5,#0d47a1)',
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={2}>
          {' '}
          <Item>
            {' '}
            <ItemBadge>{props.stats.count}</ItemBadge>Plans
          </Item>
        </Grid>
        <Grid item xs={6}>
          {' '}
          <Item>
            {' '}
            <ItemBadge>{formattedDate}</ItemBadge>Last Scrape{' '}
          </Item>
        </Grid>
        <Grid item xs={4}>
          {' '}
          <Item>
            {props.stats.scrapes.slice(0, 3).map((s) => {
              const date = new Date(s.date);

              const formattedDate = format(date, 'dd MMM yy');
              return (
                <ScrapeList key={s.date}>
                  {formattedDate} > {s.count}
                </ScrapeList>
              );
            })}
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}

function DistributorList(props) {
  function runScraper(filter_distributors) {
    fetch('/api/runScraper', {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(filter_distributors),
    }).then(
      (response) => {
        getScraperStatus().then((status) => {
          props.statusChange(status);
        });
        props.handleSetDelay(API_REFRESH_INTERVAL);
      },
      (error) => {
        console.log(error);
      }
    ); // Displaying the stringified data in an alert popup
  }

  function filterDistributor(listDistributors, distributor) {
    return listDistributors.filter(function (f) {
      return f.distributor.toUpperCase() === distributor.toUpperCase();
    });
  }

  return (
    <>
      <h3 style={{ textTransform: 'capitalize', textAlign: 'left' }}>
        {props.distributors.filter(props.filter)[0].region.toUpperCase() ===
        'VIC' ? (
          <img src={veclogo} alt='Logo' width={75} height={50} />
        ) : (
          <img src={emelogo} alt='Logo' width={125} height={25} />
        )}{' '}
        {props.header}{' '}
        <Button
          variant='outlined'
          disabled={props.status !== 'READY'}
          onClick={() => runScraper(props.distributors.filter(props.filter))}
        >
          Run
        </Button>{' '}
        <span style={{ color: props.status === 'READY' ? 'green' : 'orange' }}>
          {' '}
          {props.status}
        </span>
        {props.status !== 'READY' ? <CircularProgress /> : ''}
      </h3>
      <TableContainer>
        <Table size='small'>
          <TableBody>
            {props.distributors.filter(props.filter).map((distributor) => {
              const stats = props.scrapeStats.find((obj) => {
                return obj.key === distributor.key;
              });

              return (
                <TableRow key={distributor.distributor + distributor.plantype}>
                  <TableCell> {distributor.region} </TableCell>
                  <TableCell>
                    {distributor.energytype === 'GAS' && (
                      <WhatshotIcon sx={{ color: 'red' }} />
                    )}
                    {distributor.energytype === 'ELEC' && (
                      <ElectricBoltIcon sx={{ color: '#fdee00' }} />
                    )}{' '}
                    <br /> {distributor.energytype}
                  </TableCell>
                  <TableCell>
                    {distributor.plantype === 'home' && (
                      <HouseIcon sx={{ color: 'green' }} />
                    )}
                    {distributor.plantype === 'business' && (
                      <BusinessIcon sx={{ color: 'darkblue' }} />
                    )}{' '}
                    <br /> {distributor.plantype}
                  </TableCell>
                  <TableCell>{distributor.distributor}</TableCell>
                  <TableCell>
                    <Button
                      variant='outlined'
                      disabled={props.status !== 'READY'}
                      onClick={() =>
                        runScraper(
                          filterDistributor(
                            props.distributors.filter(props.filter),
                            distributor.distributor
                          )
                        )
                      }
                    >
                      Run
                    </Button>
                  </TableCell>
                  <TableCell>
                    {' '}
                    <ScraperStatsBadge
                      distributor_key={distributor.key}
                      controlledload={false}
                      trigger={props.status}
                    />{' '}
                    {distributor.region === 'Vic' &&
                      distributor.energytype === 'ELEC' && (
                        <ScraperStatsBadge
                          distributor_key={distributor.key}
                          controlledload={true}
                          trigger={props.status}
                        />
                      )}{' '}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => {
        clearInterval(id);
      };
    }
  }, [callback, delay]);
}

async function getScraperStatus() {
  let scrapeStatus;

  await fetch('/api/currentStatus', { cache: 'no-store' })
    .then((res) => res.json())
    .then((data) => {
      scrapeStatus = data;
    })
    .catch((err) => {
      console.log(err.message);
    });

  console.log('return currentStatus: ' + scrapeStatus)

  return scrapeStatus;
}

export default App;
